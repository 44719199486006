<template>
    <div>
        <van-nav-bar title="座位图" left-arrow @click-left="onClickLeft" />
        <div class="welcome-div">
            “2024年陕西省职业教育活动周启动仪式”座位图：
        </div>
        <van-divider style="color: #6e6e6e; border-color: #1989fa; padding-top: 25px;font-size: 16px;">
            点击图片，可进行预览缩放
        </van-divider>
        <van-image width="100%"  src="/zuoweitu.jpg" @click="showPreview=true"/>
        <van-image-preview v-model="showPreview" :images="['/zuoweitu.jpg']" :maxZoom="5" />

    </div>
</template>

<script>

export default {
    data(){
        return {
            showPreview:false
        }
    },
    methods: {
        onClickLeft() {
            this.$router.push('/')
        }
    }
};
</script>

<style scoped>
.welcome-div {
    text-indent: 2em;
    line-height: 30px;
    margin: 10px;
}

.mark {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
</style>

